import {Environment} from "@environments/types";

export const environment: Environment = {
  production: true,
  serviceWorker: true,
  showWip: false,
  loginRedirectToken: `232db4684dae8f3377baf5034d8c3c12cf1e9a99c9c07f39a45e042cf38213f7`,
  apiHost: 'https://api.consensus-labs.com/',
  storageUrl: 'https://media.consensus-labs.com/'
};
